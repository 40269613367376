<script>
export default {
    name: "locale-selector",

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: String,
            default: 'hu'
        }
    },

    computed: {
        model: {
            get: function () {
                return this.modelValue;
            },
            set: function (locale) {
                this.$emit('update:modelValue', locale);
            }
        },

        languages: function () {
            return [
                {
                    flag: require("@/assets/images/flags/us.jpg"),
                    language: "en",
                    title: this.$t('intl.languages.en'),
                },
                {
                    flag: require("@/assets/images/flags/hungary.jpg"),
                    language: "hu",
                    title: this.$t('intl.languages.hu'),
                }
            ]
        },

        language: function () {
            return this.languages.find(language => language.language === (this.model || 'hu'));
        },
    },

    methods: {
        select: function (locale) {
            this.model = locale;
        }
    }
}
</script>

<template>
    <div class="dropdown d-inline-block language-switch">
        <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn header-item"
            data-bs-toggle="dropdown"
            type="button"
        >
            <img
                :title="language.title"
                alt="Header Language"
                class="header-lang-img"
                :src="language.flag"
            />
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <a
                class="dropdown-item notify-item language"
                data-lang="eng"
                href="javascript:void(0);"
                @click="select('en')"
            >
                <img
                    alt="user-image"
                    class="me-1"
                    height="12"
                    src="@/assets/images/flags/us.jpg"
                />
                <span class="align-middle">{{ this.$t('intl.languages.en') }}</span>
            </a>
            <!-- item-->
            <a
                class="dropdown-item notify-item language"
                data-lang="hun"
                href="javascript:void(0);"
                @click="select('hu')"
            >
                <img
                    alt="user-image"
                    class="me-1"
                    height="12"
                    src="@/assets/images/flags/hungary.jpg"
                />
                <span class="align-middle">{{ this.$t('intl.languages.hu') }}</span>
            </a>
        </div>
    </div>
</template>

<style scoped>

</style>