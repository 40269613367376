<script>
import {mapGetters} from "vuex";

export default {
    name: "gender-selector",

    emits: ['update:modelValue'],

    props: {
        modelValue: {
            type: [String, Number]
        },

        size: {
            type: [String, Number],
            default: '36px'
        }
    },

    computed: {
        ...mapGetters({
            genders: 'gender/all',
            loading: 'gender/loading'
        }),

        model: {
            get: function () {
                return this.modelValue;
            },
            set: function (id) {
                this.$emit('update:modelValue', id);
            },
        },

        fontSize: function () {
            return typeof this.size === 'number' ? this.size + 'px' : this.size;
        },

        gender: function () {
            return this.genders?.find(gender => gender.id === this.model) || {};
        },

        title: function () {
            return this.loading === false ? this.$t(this.defaultGender.translation_key).ucFirst() : '';
        },

        defaultGender: function () {
            return this.model ? this.gender : (this.genders.find(gender => gender.icon === 'fas fa-venus-mars') || {});
        },
    },

    methods: {
        select: function (id) {
            this.model = id;
        },
    },

    created() {
        this.$store.dispatch('gender/all').then();
    }
}
</script>

<template>
    <div v-if="!loading" class="dropdown d-inline-block gender-selector">
        <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn header-item"
            data-bs-toggle="dropdown"
            type="button"
            :title="title"
        >
            <i :class="defaultGender.icon" :style="{color: defaultGender.color, 'font-size': fontSize}"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <template v-for="(gen) in genders" :key="gen.id">
                <a
                    class="dropdown-item notify-item gender"
                    href="javascript:void(0);"
                    @click="select(gen.id)"
                >
                    <i class="me-2" :class="gen.icon" :style="{color: gen.color}"></i>
                    <span class="align-middle">{{ $t(gen.translation_key).ucFirst() }}</span>
                </a>
            </template>
        </div>
    </div>
</template>

<style scoped>

</style>