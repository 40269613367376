<template>

    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                    <cropper
                        v-if="model.image_file && cropper"
                        v-model="model.image"
                        :file="model.image_file"
                        @update:file="(file) => model.image_file = file"
                    ></cropper>
                    <label
                        :class="{'text-danger': states.image_file === false}"
                        class="w-100 text-center form-label mb-lg-1"
                        for="image-file-input"
                    >
                        <img v-if="model.image" :src="model.image" alt="" class="rounded-circle avatar-lg"/>
                        <div v-else class="avatar-sm">
                            <span class="avatar-title rounded-circle bg-primary text-white font-size-16"> {{
                                    letter
                                }} </span>
                        </div>
                    </label>
                    <div class="align-items-center d-flex">
                        <input
                            id="image-file-input"
                            :class="{'border-danger': states.image_file === false || isRequiredField('image_id')}"
                            class="form-control"
                            name="image_file"
                            type="file"
                            @change="uploadImage"
                        />
                        <div :class="{'d-block': states.image_file === false}" class="invalid-feedback">
                            {{ errors.image_file }}
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4 mt-lg-2 text-center text-sm-start">
                    <label
                        :class="{'text-danger': states.locale === false}"
                        class="form-label col-sm-12"
                        for="locale-input"
                    >{{ $t('partners::staffs.columns.locale').ucFirst() }}</label
                    >
                    <locale-selector id="locale-input" name="locale-input" v-model="model.locale"></locale-selector>
                    <div :class="{'d-block': states.locale === false}" class="invalid-feedback">
                        {{ errors.locale }}
                    </div>
                </div>
                <div class="col-6 col-md-4 col-lg-3 mb-4 mt-lg-2 text-center text-sm-start">
                    <label
                        :class="{'text-danger': states.gender_id === false}"
                        class="form-label col-sm-12"
                        for="gender-id-input"
                    >{{ $t('partners::staffs.columns.gender_id').ucFirst() }}</label
                    >
                    <gender-selector id="gender-id-input" name="gender-id-input" v-model="model.gender_id"></gender-selector>
                    <div :class="{'d-block': states.gender_id === false}" class="invalid-feedback">
                        {{ errors.gender_id }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-2 mb-4 mt-lg-2">
                    <label
                        :class="{'text-danger': states.prefix === false}"
                        class="form-label"
                        for="prefix-input"
                    >{{ $t('partners::staffs.columns.prefix').ucFirst() }}</label
                    >
                    <input
                        id="prefix-input"
                        v-model="model.prefix"
                        :class="{'border-danger': states.prefix === false}"
                        :placeholder="$t('partners::staffs.placeholders.prefix').ucFirst()"
                        class="form-control"
                        name="prefix"
                        type="text"
                    />
                    <div :class="{'d-block': states.prefix === false}" class="invalid-feedback">
                        {{ errors.prefix }}
                    </div>
                </div>
                <div v-if="!name_order" class="col-12 col-sm-6 col-md-4 col-lg-5 mb-4 mt-lg-2">
                    <label
                        :class="{'text-danger': states.firstname === false}"
                        class="form-label"
                        for="firstname-input"
                    >{{ $t('partners::staffs.columns.firstname').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="firstname-input"
                        v-model="model.firstname"
                        :class="{'border-danger': states.firstname === false}"
                        :placeholder="$t('partners::staffs.placeholders.firstname')"
                        class="form-control"
                        name="firstname"
                        type="text"
                    />
                    <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                        {{ errors.firstname }}
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-5 mb-4 mt-lg-2">
                    <label
                        :class="{'text-danger': states.lastname === false}"
                        class="form-label"
                        for="lastname-input"
                    >{{ $t('partners::staffs.columns.lastname').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="lastname-input"
                        v-model="model.lastname"
                        :class="{'border-danger': states.lastname === false}"
                        :placeholder="$t('partners::staffs.placeholders.lastname').ucFirst()"
                        class="form-control"
                        name="lastname"
                        type="text"
                    />
                    <div :class="{'d-block': states.lastname === false}" class="invalid-feedback">{{
                            errors.lastname
                        }}
                    </div>
                </div>
                <div v-if="name_order" class="col-12 col-sm-6 col-md-4 col-lg-5 mb-4 mt-lg-2">
                    <label
                        :class="{'text-danger': states.firstname === false}"
                        class="form-label"
                        for="firstname-input"
                    >{{ $t('partners::staffs.columns.firstname').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <input
                        id="firstname-input"
                        v-model="model.firstname"
                        :class="{'border-danger': states.firstname === false}"
                        :placeholder="$t('partners::staffs.placeholders.firstname')"
                        class="form-control"
                        name="firstname"
                        type="text"
                    />
                    <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                        {{ errors.firstname }}
                    </div>
                </div>
                <div v-if="false" class="col-12 col-md-8 col-lg-6">
                    <div class="row" :class="{'flex-row-reverse flex-xs-range-column-reverse': name_order}">
                        <div class="col-12 col-sm-6 mb-4 mt-lg-2">
                            <label
                                :class="{'text-danger': states.firstname === false}"
                                class="form-label"
                                for="firstname-input"
                            >{{ $t('partners::staffs.columns.firstname').ucFirst() }} <span class="text-primary">*</span></label
                            >
                            <input
                                id="firstname-input"
                                v-model="model.firstname"
                                :class="{'border-danger': states.firstname === false}"
                                :placeholder="$t('partners::staffs.placeholders.firstname')"
                                class="form-control"
                                name="firstname"
                                type="text"
                            />
                            <div :class="{'d-block': states.firstname === false}" class="invalid-feedback">
                                {{ errors.firstname }}
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 mb-4 mt-lg-2">
                            <label
                                :class="{'text-danger': states.lastname === false}"
                                class="form-label"
                                for="lastname-input"
                            >{{ $t('partners::staffs.columns.lastname').ucFirst() }} <span class="text-primary">*</span></label
                            >
                            <input
                                id="lastname-input"
                                v-model="model.lastname"
                                :class="{'border-danger': states.lastname === false}"
                                :placeholder="$t('partners::staffs.placeholders.lastname').ucFirst()"
                                class="form-control"
                                name="lastname"
                                type="text"
                            />
                            <div :class="{'d-block': states.lastname === false}" class="invalid-feedback">{{
                                    errors.lastname
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-sm-4 col-xl-2">
                    <label
                        class="form-label"
                        for="name-type-input"
                    >{{ $t('partners::staffs.name_order').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <label class="form-check-label" for="name-type-input">{{
                                $t('partners::staffs.western_name_order')
                            }}</label>
                        <input id="name-type-input" v-model="name_order" class="form-check-input float-none mx-3"
                               type="checkbox">
                        <label class="form-check-label" for="name-type-input">{{
                                $t('partners::staffs.eastern_name_order')
                            }}</label>
                    </div>
                </div>
                <div class="col-12 col-sm-8 col-xl-10">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name-input"
                    >
                        <span>{{ $t('partners::staffs.columns.name').ucFirst() }}</span>
                        <span v-if="isRequiredField('name')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <input
                        id="name-input"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false || isRequiredField('name')}"
                        :placeholder="$t('partners::staffs.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        readonly
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{
                            errors.name
                        }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-sm-6 col-lg-4">
                    <label
                        :class="{'text-danger': states.email === false}"
                        class="form-label"
                        for="email-input"
                    >
                        <span>{{ $t('partners::staffs.columns.email').ucFirst() }}</span>
                        <span v-if="isRequiredField('email')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <input
                        id="email-input"
                        v-model="model.email"
                        :class="{'border-danger': states.email === false || isRequiredField('email')}"
                        :placeholder="$t('partners::staffs.placeholders.email').ucFirst()"
                        class="form-control"
                        name="email"
                        type="email"
                    />
                    <div :class="{'d-block': states.email === false}" class="invalid-feedback">{{
                            errors.email
                        }}
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <label
                        :class="{'text-danger': states.phone === false}"
                        class="form-label"
                        for="phone-input"
                    >
                        <span>{{ $t('partners::staffs.columns.phone').ucFirst() }}</span>
                        <span v-if="isRequiredField('phone')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <vue-tel-input
                        id="phone-input"
                        v-model="phone"
                        :inputOptions="{
                            id: 'phone-input',
                            placeholder: $t('partners::staffs.placeholders.phone').ucFirst()
                        }"
                        :styleClasses="{'border-danger': states.phone === false || isRequiredField('phone')}"
                        :invalidMsg="errors.phone"
                        defaultCountry="hu"
                        validCharactersOnly
                        mode="international"
                    ></vue-tel-input>
                    <div :class="{'d-block': states.phone === false}" class="invalid-feedback">{{
                            errors.phone
                        }}
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <label
                        :class="{'text-danger': states.position_id === false}"
                        class="form-label"
                        for="position_id-input"
                    >
                        <span>{{ $t('partners::staffs.columns.position_id').ucFirst() }}</span>
                        <span v-if="isRequiredField('position_id')" class="ms-2 text-danger">*</span>
                    </label
                    >
                    <select
                        name="position_id"
                        id="position_id-input"
                        v-model="model.position_id"
                        class="form-select"
                        :class="{'border-danger': states.position_id === false || isRequiredField('position_id')}"
                    >
                        <option :value="model.position_id === undefined ? undefined : null">{{ $t('partners::staffs.placeholders.position_id').ucFirst() }}</option>
                        <option v-for="(position, index) in positions" :key="index" :value="position.id">{{ position.translation_key ? $t(position.translation_key).ucFirst() : position.name }}</option>
                    </select>
                    <div :class="{'d-block': states.position_id === false}" class="invalid-feedback">{{
                            errors.position_id
                        }}
                    </div>
                </div>
            </div>
            <template v-for="(field, index) in fields" :key="index">

                <additional-versionable-field
                    v-if="field.versionable"
                    v-model="model[field.transformed_name]"
                    :is-required="isRequiredField(field.transformed_name)"
                    :field="field"
                    :state="states[field.transformed_name]"
                    :error="errors[field.transformed_name]"
                    @update:modelValue="newValue => model[field.transformed_name] = newValue"
                ></additional-versionable-field>

                <div v-else class="row mb-4">
                    <additional-field
                        v-model="model[field.transformed_name]"
                        :is-required="isRequiredField(field.transformed_name)"
                        :field="field"
                        :state="states[field.transformed_name]"
                        :error="errors[field.transformed_name]"
                        @update:modelValue="newValue => model[field.transformed_name] = newValue"
                        @upload="uploaded => files[field.transformed_name] = uploaded"
                    ></additional-field>
                </div>
            </template>

            <div class="row justify-content-end">
                <div class="col-12">
                    <div>
                        <button class="btn btn-primary w-md" type="submit" @click.prevent="save">
                            {{ $t('base.save').ucFirst() }}
                        </button>
                        <slot name="buttons"></slot>
                    </div>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Cropper from "../elements/cropper.vue";
import AdditionalVersionableField from "../elements/additional-versionable-field.vue";
import AdditionalField from "../elements/additional-field.vue";
import LocaleSelector from "../elements/locale-selector.vue";
import GenderSelector from "../elements/gender-selector.vue";

export default {
    name: "DecisionMakerForm",

    components: {GenderSelector, LocaleSelector, AdditionalField, AdditionalVersionableField, Cropper},

    emits: ['saved'],

    props: {
        decisionMaker: {
            type: Object,
            required: true
        },

        requiredFields: {
            type: Array,
            default: () => {
                return [];
            }
        },

        partnerId: {
            type: Number
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'decisionMaker/loading',
            'states': 'decisionMaker/states',
            'errors': 'decisionMaker/errors',
            'positions': 'position/all',
            fields: 'decisionMaker/fields',
            fieldVersions: 'fieldVersion/all'
        }),

        letter: function () {
            return this.model && this.model.firstname ? this.model.firstname[0] : '?'
        }
    },

    data: function () {
        return {
            model: {},
            files: {},
            name_order: true, // false = western, true = eastern
            cropper: false,
            phone: (this.decisionMaker && this.decisionMaker.phone) || ''
        }
    },

    methods: {
        uploadImage: function (e) {
            if (e.target.files.length) {
                const file = e.target.files[0];
                this.model.image = file ? URL.createObjectURL(file) : this.model.image;
                this.model.image_file = e.target.files[0]
                this.cropper = true
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            let data = Object.assign({partner_id: this.partnerId, with: 'position'}, this.model, this.files)

            delete data.image

            this.$store.dispatch(`decisionMaker/${method}`, data).then(saved => {
                this.setModel(saved);
                this.cropper = false
                this.$emit('saved')
            })
        },

        isRequiredField: function (field) {
            return this.requiredFields?.includes(field);
        },

        setModel: function (model = {}) {
            const base = {
                locale: 'hu'
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));

            if (!this.model.locale) {
                this.model.locale = 'hu';
            }
        },
    },

    watch: {
        'model.firstname': function (val) {
            const fn = val || ''
            const ln = this.model.lastname || ''

            this.model.name = this.name_order
                ? ln + ' ' + fn
                : fn + ' ' + ln
        },

        'model.lastname': function (val) {
            const fn = this.model.firstname || ''
            const ln = val || ''

            this.model.name = this.name_order
                ? ln + ' ' + fn
                : fn + ' ' + ln
        },

        name_order: function (val) {
            const fn = this.model.firstname || ''
            const ln = this.model.lastname || ''

            this.model.name = val
                ? ln + ' ' + fn
                : fn + ' ' + ln
        },

        decisionMaker: {
            immediate: true,
            handler: function (decisionMaker) {
                this.setModel(decisionMaker);
                this.phone = (decisionMaker && decisionMaker.phone) || '';
            }
        },

        phone: function (value) {
            this.model.phone = value
            //.replace(/\s|\t|\n|\+/g, '')
        },
    },

    created() {
        if (! this.positions || ! this.positions.length) {
            this.$store.dispatch('position/all')
        }
        this.$store.dispatch('decisionMaker/fields', {owner_type: 'Appon\\Partners\\Models\\DecisionMaker', with: 'fieldType'})
    }
}
</script>

<style scoped>

</style>
