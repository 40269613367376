<script>
import MemberCard from "./member-card.vue";
import Avatar from "./user/avatar.vue";
import UserSearcher from "./user-searcher.vue";

export default {
    name: "related-user-manager",

    components: {UserSearcher, Avatar, MemberCard},

    emits: ['update:modelValue', 'update:user'],

    props: {
        modelValue: {},

        store: {
            type: String,
            default: 'user'
        },

        id: {
            type: [String, Number]
        },

        allState: {
            type: String,
            default: 'allUser'
        },

        pivotState: {
            type: String,
            default: 'userPivots'
        },

        loadingState: {
            type: String,
            default: 'userLoading'
        },

        contributorsAllowed: {
            type: Boolean,
            default: false
        },

        onlyImplementers: {
            type: Boolean,
            default: false
        },

        allAction: {
            type: String,
            default: 'allUser'
        },

        syncAction: {
            type: String,
            default: 'sync'
        },

        displayType: {
            type: String,
            default: 'avatar'
            // can be avatar or card
        },

        disabled: {
            type: Boolean,
            default: false
        },

        list: {
            type: Boolean,
            default: true
        },

        user: {
            type: Object
        },

        valueField: {
            type: [String, null],
            default: 'id'
        },

        returnUser: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        all: function () {
            if (! this.list) {
                return [];
            }

            return this.id
                ? this.$store.getters[this.store + '/' + this.allState]
                : this.model;
        },

        pivots: function () {
            return this.id
                ? this.$store.getters[this.store + '/' + this.pivotState] || []
                : JSON.parse(JSON.stringify(this.model)) || [];
        },

        loading: function () {
            return this.$store.getters[this.store + '/' + this.loadingState];
        },

        singleTypeExcludes: function () {
            return this.all?.map(m => m.id) || [];
        },

        multiTypeExcludes: function () {
            return this.all?.map(m => {
                return {type: m.type || m.pivot?.user_type?.afterLast('\\'), id: m.id};
            }) || [];
        },

        excludes: function () {
            return this.contributorsAllowed
                ? this.multiTypeExcludes
                : this.singleTypeExcludes;
        },

        defaultUser: {
            get: function () {
                return this.user;
            },
            set: function (user) {
                this.$emit('update:user', user);
            }
        }
    },

    methods: {
        fetch: function () {
            this.$store.dispatch(this.store + '/' + this.allAction, this.id)
        },

        updateModelValue: function (value) {
            this.model = value;
            this.$emit('update:modelValue', this.model);
        },

        sync: function (pivots) {
            this.id
                ? this.$store.dispatch(this.store + '/' + this.syncAction, {id: this.id, pivots: pivots})
                : this.updateModelValue(pivots);
        },

        singleAdd: function (user) {
            const pivots = JSON.parse(JSON.stringify(this.pivots));
            pivots.push(user);
            this.sync(pivots)
        },

        multipleAdd: function (user) {
            const pivots = JSON.parse(JSON.stringify(this.pivots));
            pivots.push(user);
            this.sync(pivots)
        },

        add: function (user) {
            if (!user) {
                return;
            }

            if (this.list) {
                this.contributorsAllowed
                    ? this.multipleAdd(user)
                    : this.singleAdd(user);
            } else {
                this.updateModelValue(this.returnUser ? user : user[this.valueField]);
                this.$emit('update:user', user);
            }
            this.open = !this.open;
            this.updateKey++;
        },

        singleRemove: function (user) {
            const index = this.pivots.findIndex(piv => piv.id === user.id);

            const pivots = JSON.parse(JSON.stringify(this.pivots));

            pivots.splice(index, 1);

            this.sync(pivots);
        },

        multipleRemove: function (user) {
            const pivots = JSON.parse(JSON.stringify(this.pivots)) || [];

            const mapped = this.pivots.map(m => {
                return {type: m.type || m.pivot?.user_type?.afterLast('\\'), id: m.id};
            }) || [];

            const index = mapped.findIndex(piv => piv.id === user.id && piv.type === user.type);

            pivots.splice(index, 1);

            this.sync(pivots);
        },

        remove: function (user) {
            if (this.disabled) {
                return;
            }

            if (this.list) {
                this.contributorsAllowed
                    ? this.multipleRemove(user)
                    : this.singleRemove(user);
            } else {
                this.updateModelValue(null);
                this.$emit('update:user', {});
            }

            this.updateKey++;
        },

        toggle: function () {
            this.open = !this.open;
        },

        setDirection: function () {
            const toRight = window.innerWidth - this.$refs['open-btn'].getBoundingClientRect().right;

            this.direction = toRight < 260
                ? 'left'
                : 'right';
        },
    },

    watch: {
        id: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    if (!this.model) {
                        this.fetch();
                    }
                }
            },
        },

        modelValue: {
            immediate: true,
            deep: true,
            handler: function (value) {
                if ((!this.model || (Array.isArray(this.model) && !this.model.length)) && !!value) {
                    this.model = JSON.parse(JSON.stringify(value));
                }
            },
        }
    },

    data: function () {
        return {
            open: false,
            direction: 'right',
            updateKey: 1,
            model: this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : (this.modelValue || (this.returnUser ? null : []))
        };
    },

    mounted: function () {
        this.setDirection();
    },
}
</script>

<template>
    <div class="row" :key="updateKey">
        <div :class="{'col-lg-9': displayType === 'card', 'col-lg-12': displayType === 'avatar'}">
            <b-overlay :show="loading">
                <div v-if="displayType === 'card'" class="row">
                    <div v-for="(user) in all" :key="user.id" class="col-xl-4 col-sm-6">
                        <member-card :user="user" @remove="remove"></member-card>
                    </div>
                </div>
                <div v-else class="avatar-group">
                    <template v-if="list">
                        <avatar v-for="(user) in all" :key="user.id" :user="user" :link="false" :hover="true" class="avatar-group-item" @click="remove(user)">
                            <template #hover v-if="!disabled">
                            <span class="avatar-hover d-none bg-danger avatar-title rounded-circle text-white font-size-16 user-select-none">
                                <i class="fas fa-trash-alt"></i>
                            </span>
                            </template>
                        </avatar>
                    </template>
                    <template v-else>

                    </template>
                    <div v-if="!disabled" class="position-relative">
                        <div ref="open-btn" class="avatar-item avatar-group-item">
                            <div class="cursor-pointer avatar-sm" @click="open = !open">
                                <span v-if="list || !model" :title="$t('base.add').ucFirst()" class="avatar-adding-btn avatar-title rounded-circle text-white user-select-none font-size-16 bg-soft-primary">
                                    <i class="mdi mdi-account-plus"></i>
                                </span>
                                <template v-else-if="!list && model">
                                    <avatar :link="false" :user="model" :key="model.id"></avatar>
                                </template>
                            </div>
                        </div>
                        <user-searcher v-if="open" v-click-outside="toggle" class="user-search-wrapper" :class="{'user-search-wrapper-left': direction === 'left'}" :contributors-allowed="contributorsAllowed" :only-implementers="onlyImplementers" :excludes="excludes" @select="add"></user-searcher>
                    </div>
                </div>
            </b-overlay>
        </div>
        <div v-if="displayType === 'card'" :class="{'col-lg-3': displayType === 'card'}">
            <user-searcher :excludes="excludes" @select="add"></user-searcher>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "@/assets/scss/_variables.scss";

.avatar-adding-btn {
    transition: 0.3s;
    &:hover {
        transition: 0.3s;
        background-color: $primary !important;
    }
}

.user-search-wrapper {
    border: 1px solid $border-color;
    width: 260px;
    top: 0;
    left: calc(100% + 5px );
    position: absolute;
    z-index: 3000;
    box-shadow: 0 5px 6px rgba(20, 27, 43, 0.1);
    padding: 10px;
}

.user-search-wrapper-left {
    top: 100%;
    left: -265px;
}
</style>
