<script>
import {mapGetters} from "vuex";

export default {
    name: "profile-card",

    props: {
        user: {
            type: Object
        },
        superior: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            model: {}
        }
    },

    computed: {
        ...mapGetters({
            authenticated: 'user/authenticated',
            profile: 'user/profile',
            loading: 'user/loading',
        }),

        item: function () {
            return this.user || this.profile
        },

        isOwner: function () {
            return this.authenticated?.id === this.profile?.id;
        },
    },

    methods: {
        setModel: function (value) {
            this.model = Object.assign({}, value)
        },
    },

    watch: {
        profile: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
            },
        }
    },
}
</script>

<template>
    <b-overlay :show="loading">
        <div class="card">
            <div class="card-body p-0">
                <div class="user-profile-img">
                    <img
                        src="@/assets/images/pattern-bg.jpg"
                        class="profile-img profile-foreground-img rounded-top"
                        style="height: 120px"
                        alt=""
                    />
                    <div class="overlay-content rounded-top">
                        <div>
                            <div class="user-nav p-3">
                                <div class="d-flex justify-content-end">
                                    <div class="dropdown">
                                        <a
                                            class="dropdown-toggle"
                                            href="#"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i class="bx bx-dots-vertical-rounded text-white"></i>
                                        </a>

                                        <ul class="dropdown-menu dropdown-menu-end">
                                            <li v-if="isOwner">
                                                <router-link class="dropdown-item" :to="$t('routes./users/profile')">{{ $t('base.edit').ucFirst() }}</router-link>
                                            </li>
                                            <li v-if="superior">
                                                <router-link class="dropdown-item" :to="$t('routes./users/:id(\\d+)', {id: this.$route.params.id}).rmValidation()">{{ $t('base.edit').ucFirst() }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end user-profile-img -->

                <div class="mt-n5 position-relative">
                    <div class="text-center">
                        <img v-if="item.image" :alt="item.name" :src="item.image"
                             :title="item.name" class="avatar-xl rounded-circle img-thumbnail"
                             :style="{borderWidth: '2px', backgroundColor: item?.gender?.color}"
                        />
                        <div v-else class="avatar-lg d-inline-block">
                        <span class="avatar-title rounded-circle bg-soft-primary text-white font-size-26"> {{
                                item.name?.firstLetters()
                            }} </span>
                        </div>

                        <div class="mt-3">
                            <h5 class="mb-1">{{ item.name }}</h5>
                            <h6 class="text-muted text-uppercase mb-3 font-size-10">
                                <template v-for="(position) in item.positions" :key="position.id">
                                    <i v-if="position.icon" :class="position.icon" :style="{color: position.color}" class="me-2"></i>
                                    <span>{{ position.translation_key ? $t(position.translation_key).ucFirst() : position.name }}</span>
                                    <span v-if="position.knowledge_level" class="mx-2">|</span>
                                    <i v-if="position.knowledge_level?.icon" :class="position.knowledge_level?.icon" :style="{color: position.knowledge_level?.color}" class="font-size-10 me-2"></i>
                                    <span v-if="position.knowledge_level">{{ position.knowledge_level?.translation_key ? $t(position.knowledge_level.translation_key).ucFirst() : position.knowledge_level?.name }}</span>
                                </template>
                            </h6>
                            <div>
                                <a v-for="(skill) in item.skills" :key="skill.id" href="#" class="badge m-1"
                                   :class="{'badge-success': !skill.color}"
                                   :style="{backgroundColor: skill.color}"
                                >
                                    <div class="d-flex align-items-center">
                                        <i v-if="skill.icon" :class="skill.icon" class="me-1 font-size-9"></i>
                                        <span>{{ skill.translation_key ? $t(skill.translation_key).ucFirst() : skill.name }}</span>
                                    </div>
                                </a>
                            </div>

                            <div class="mt-4" v-if="false">
                                <a
                                    href=""
                                    class="btn btn-primary waves-effect waves-light btn-sm"
                                ><i class="bx bx-send me-1 align-middle"></i> Send
                                    Message</a
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-3 mt-3">
                    <div class="row text-center">
                        <div class="col-6 border-end">
                            <div class="p-1">
                                <h5 class="mb-1">{{ item.partners_count }}</h5>
                                <p class="text-muted mb-0">{{ $tc('partners::partners.partner', 2).ucFirst() }}</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="p-1">
                                <h5 class="mb-1">{{ item.successful_projects }}</h5>
                                <p class="text-muted mb-0">{{ $t('base.successful_item', {item: $tc('projects::projects.project', 2)}).ucFirst() }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </b-overlay>
</template>

<style scoped>

</style>
