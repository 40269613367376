<script>
import ProfileCard from "../../../components/elements/user/profile-card.vue";
import PersonalInformationCard from "../../../components/elements/user/personal-information-card.vue";
import SubordinatesCard from "../../../components/elements/user/subordinates-card.vue";
import StatisticCard from "../../../components/elements/user/statistic-card.vue";
import {mapGetters} from "vuex";
import TabCard from "../../../components/elements/tab-card.vue";
import TabNav from "../../../components/elements/tab-nav.vue";
import TabPanel from "../../../components/elements/tab-panel.vue";
import SuperiorCard from "../../../components/elements/user/superior-card.vue";
import Performance from "../../../components/elements/user/performance.vue";
import Reminders from "../../../components/elements/user/reminders.vue";
import Meetings from "../../../components/elements/user/meetings.vue";
import TransitionToggle from "../../../components/transitions/toggle.vue";
import ProcessProjectMatrix from "../../../components/elements/user/process-project-matrix.vue";
import PartnerGrid from "../../../components/elements/user/partner-grid.vue";
import Today from "../../../components/elements/user/today.vue";
import PersonalQuantityTargetsCard from "../../../components/elements/user/personal-quantity-targets-card.vue";
import PersonalPerformance from "../../../components/elements/user/personal-performance.vue";
import TeamCard from "../../../components/elements/user/team-card.vue";
import TaskCard from "../../../components/elements/user/task-card.vue";
import WeeklyPerformance from "../../../components/elements/user/weekly-performance.vue";

export default {
    name: 'public-profile',

    components: {
        WeeklyPerformance,
        TaskCard,
        TeamCard,
        PersonalPerformance,
        PersonalQuantityTargetsCard,
        Today,
        PartnerGrid,
        ProcessProjectMatrix,
        TransitionToggle,
        Meetings,
        Reminders,
        Performance,
        SuperiorCard,
        TabPanel,
        TabNav,
        TabCard,
        StatisticCard,
        SubordinatesCard,
        PersonalInformationCard,
        ProfileCard
    },

    computed: {
        ...mapGetters({
            loading: 'user/loading',
            user: 'user/profile',
            authenticated: 'user/authenticated'
        }),

        owner: function () {
            return this.user?.id === this.authenticated?.id;
        },

        partnerFields: function () {
            return [
                {
                    key: "code",
                    label: this.$t('partners::partners.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('partners::partners.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
            ];
        }
    },

    data() {
        return {
            calendar: false,
            superior: false
        };
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (value?.name) {
                    this.$store.dispatch('app/title', {key: value?.name})
                } else {
                    this.$store.dispatch('app/title', {key: ''})
                }
            },
        },

        '$route.params.id': {
            deep: true,
            immediate: true,
            handler: function (id) {
                this.superior = false;
                this.$store.dispatch('user/clearProfile');
                if (id && this.$route.name === 'public-profile') {
                    this.$store.dispatch('user/profile', id);
                    this.$store.dispatch('user/checkSubordinate', id).then(response => {
                        this.superior = response.is_subordinate?.[id] || false;
                        if (this.superior || this.owner) {
                            this.$store.dispatch('performance/show', {id: id});
                        }
                    });
                }
            },
        }
    },

    created() {
        console.log(this.$router)
    },

    unmounted() {
        this.$store.dispatch('app/title', {key: ''})
        this.$store.dispatch('user/clearProfile')
    }
};
</script>

<template>
    <div class="row">
        <div class="col-xxl-3">
            <div class="user-sidebar">
                <profile-card :superior="superior"></profile-card>

                <performance v-if="(superior || owner) && false" :owner="owner"></performance>

                <weekly-performance v-if="superior || owner" :id="user?.id" :owner="owner"></weekly-performance>

                <personal-information-card v-if="user?.introduction"></personal-information-card>

                <template v-for="(team) in user?.teams" :key="team.id">
                    <team-card :loading="loading" :team="team"></team-card>
                </template>

                <superior-card></superior-card>

                <subordinates-card></subordinates-card>
            </div>
        </div>

        <div class="col-xxl-9">
            <div class="row">
                <div :class="{'col-xl-9': superior, 'col-12': !superior}">
                    <div class="row">
                        <div :class="{'col-xl-10': superior, 'col-12': !superior}">
                            <div class="row">
                                <div class="col-xl-4 col-lg-6">
                                    <statistic-card :loading="loading" :title="$tc('comments::comments.comment',2).ucFirst()" :value="user?.comments_count || '?'" icon-class="fas fa-comment"></statistic-card>
                                </div>
                                <div class="col-xl-4 col-lg-6">
                                    <statistic-card :loading="loading" :title="$tc('comments::comments.mentioned',2).ucFirst()" :value="user?.mentioned_count || '?'" icon-class="bx bx-user-pin"></statistic-card>
                                </div>
                                <div class="col-xl-4 col-lg-6">
                                    <statistic-card :loading="loading" :title="$tc('interactions::interactions.interaction',2).ucFirst()" :value="user?.interactions_count || '?'" icon-class="bx bxs-phone-outgoing"></statistic-card>
                                </div>
                            </div>
                        </div>
                        <div v-if="superior" class="col-xl-2">
                            <div class="card cursor-pointer" :title="$t('base.calendar').ucFirst()" @click="calendar = !calendar">
                                <div class="card-body bg-gradient rounded transition" :class="{'bg-soft-secondary': !calendar, 'bg-primary': calendar}">
                                    <div class="d-flex h-100 align-items-center justify-content-center">
                                        <i class="font-size-50 fas fa-calendar-alt" :class="{'text-white': calendar, 'text-primary opacity-75': !calendar}"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="superior" class="row">
                        <div class="col-12">
                            <reminders :id="user?.id"></reminders>
                        </div>
                    </div>
                </div>
                <div v-if="superior" class="col-xl-3">
                    <today class="ms-3"></today>
                </div>
            </div>
            <!-- end row -->

            <transition-toggle>
                <div class="row" v-if="calendar">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <meetings></meetings>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-toggle>
            <!-- end row -->

            <tab-card>
                <template #nav="{setActive}">
                    <tab-nav active name="projects" @click="setActive(0)" icon="far fa-handshake" :text="$tc('projects::projects.project',2).ucFirst()"></tab-nav>
                    <tab-nav name="partners" @click="setActive(1)" icon="far fa-handshake" :text="$tc('partners::partners.partner',2).ucFirst()"></tab-nav>
                    <tab-nav v-if="superior" @click="setActive(2)" name="tasks" :text="$tc('tasks::tasks.task',2).ucFirst()" icon="bx bx-clipboard"></tab-nav>
                    <tab-nav v-if="superior && false" @click="setActive(3)" name="targets" :text="$tc('projects::target_values.target_value',2).ucFirst()" icon="bx bx-bullseye"></tab-nav>
                    <tab-nav v-if="superior" @click="setActive(4)" name="performance" :text="$t('projects::performance.performance').ucFirst()" icon="mdi mdi-speedometer"></tab-nav>
                </template>
                <template #content="{active}">
                    <tab-panel :active="active === 0" name="projects">
                        <b-overlay :show="loading">
                            <div class="card">
                                <process-project-matrix :items="user?.processes"></process-project-matrix>
                            </div>
                        </b-overlay>
                    </tab-panel>
                    <tab-panel :active="active === 1" name="partners">
                        <b-overlay :show="loading">
                            <partner-grid :items="user?.partners"></partner-grid>
                        </b-overlay>
                    </tab-panel>
                    <tab-panel :active="active === 2" v-if="superior" name="tasks">
                        <task-card v-if="active === 2" :loading="loading"></task-card>
                    </tab-panel>
                    <tab-panel v-if="superior && false" :active="active === 3" name="targets">
                        <personal-quantity-targets-card :user="user"></personal-quantity-targets-card>
                    </tab-panel>
                    <tab-panel v-if="superior" :active="active === 4" name="performance">
                        <personal-performance :user="user"></personal-performance>
                    </tab-panel>
                </template>
            </tab-card>
        </div>
    </div>
</template>
