<script>
import {mapGetters} from "vuex";
import DecisionMakerForm from "../../../components/forms/decision-maker-form.vue";
import DecisionMakerCard from "../../../components/elements/decision-maker-card.vue";

export default {
    components: {DecisionMakerCard, DecisionMakerForm},

    emits: ['saved'],

    props: {
        partnerId: {
            type: Number
        },

        requiredFields: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            loading: 'decisionMaker/loading',
            decisionMaker: 'decisionMaker/item',
            decisionMakers: 'decisionMaker/list'
        }),

        showForm: function () {
            return this.decisionMaker.id || this.create
        }
    },

    data: function () {
        return {
            create: false,
            height: '100%'
        }
    },

    methods: {
        fetchList: function () {
            this.$store.dispatch('decisionMaker/list', {partner_id: this.partnerId, /*with: 'position'*/}) // || this.$route.params.id
        },

        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },

        emptyContact: function () {
            this.fetchList()
            this.$store.dispatch('decisionMaker/clearItem')
            this.create = false;
            this.$emit('saved')
        },

        openCreate: function () {
            this.create = !this.create;
            this.$store.dispatch('decisionMaker/clearItem')
        },

        close: function () {
            this.$store.dispatch('decisionMaker/clearItem');
            this.create = false;
        },
    },

    watch: {
        partnerId: {
            immediate: true,
            handler: function (id) {
                if (id) {
                    this.fetchList();
                }
            },
        }
    }
}
</script>

<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 text-start">
                <b-button variant="primary" @click="openCreate">{{ $t('base.create').ucFirst() }}</b-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12 py-3 rounded mb-4" :class="{'bg-light': showForm}" ref="trans">
                <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
                    <decision-maker-form v-show="showForm" :partner-id="partnerId" :decision-maker="decisionMaker" :required-fields="requiredFields" @saved="emptyContact">
                        <template #buttons>
                            <b-button variant="secondary" class="ms-2" @click="close">{{ $t('base.close').ucFirst() }}</b-button>
                        </template>
                    </decision-maker-form>
                </transition>
            </div>
            <div v-if="decisionMakers && decisionMakers.length" class="col-12" :class="{'col-md-6 col-lg-9': showForm}">
                <b-overlay :show="loading">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-for="(decisionMaker, index) in decisionMakers" :key="index">
                            <decision-maker-card :decision-maker="decisionMaker"></decision-maker-card>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<style scoped>

@keyframes slide-left {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        width: 100%;
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    width: 0;
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.5s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.5s ease-in;
    overflow: hidden;
}
</style>
