<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.code === false}"
                        class="form-label"
                        for="code"
                    >
                        <span>{{ $t('partners::partners.columns.code').ucFirst() }}</span>
                        <span v-if="isRequiredField('code')" class="ms-2 text-warning">*</span>
                    </label
                    >

                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('code')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="code"
                            v-model="model.code"
                            :class="{'border-danger': states.code === false || isRequiredField('code')}"
                            :placeholder="$t('partners::partners.placeholders.code').ucFirst()"
                            class="form-control"
                            type="text"
                            :readonly="merge"
                        />
                    </div>

                    <div :class="{'d-block': states.code === false}" class="invalid-feedback">{{ errors.code }}</div>
                </div>
                <div :class="{'col-lg-8': !oneLine, 'col-12': oneLine}">

                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('partners::partners.columns.name').ucFirst() }} <span class="text-primary">*</span></label
                    >
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('name')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="name"
                            v-model="model.name"
                            :class="{'border-danger': states.name === false || isRequiredField('name')}"
                            :placeholder="$t('partners::partners.placeholders.name').ucFirst()"
                            class="form-control"
                            name="name"
                            type="text"
                            :readonly="merge"
                        />
                    </div>
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.registration_number === false}"
                        class="form-label"
                        for="registration-number"
                    >
                        <span>{{ $t('partners::partners.columns.registration_number').ucFirst() }}</span>
                        <span v-if="isRequiredField('registration_number')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('registration_number')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="registration-number"
                            v-model="model.registration_number"
                            :class="{'border-danger': states.registration_number === false || isRequiredField('registration_number')}"
                            :placeholder="$t('partners::partners.placeholders.registration_number').ucFirst()"
                            class="form-control"
                            type="text"
                            :readonly="merge"
                        />
                    </div>
                    <div :class="{'d-block': states.registration_number === false}" class="invalid-feedback">
                        {{ errors.registration_number }}
                    </div>
                </div>
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.vat_number === false}"
                        class="form-label"
                        for="vat_number"
                    >
                        <span>{{ $t('partners::partners.columns.vat_number').ucFirst() }}</span>
                        <span v-if="vatNumberIsRequired" class="text-primary ms-2">*</span>
                        <span v-else-if="isRequiredField('vat_number')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('vat_number')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="vat_number"
                            v-model="model.vat_number"
                            v-maska="'########-#-##'"
                            :class="{'border-danger': states.vat_number === false || isRequiredField('vat_number')}"
                            :placeholder="$t('partners::partners.placeholders.vat_number').ucFirst()"
                            class="form-control"
                            type="text"
                            :readonly="merge"
                        />
                    </div>
                    <div :class="{'d-block': states.vat_number === false}" class="invalid-feedback">{{
                            errors.vat_number
                        }}
                    </div>
                </div>
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.international_vat_number === false}"
                        class="form-label"
                        for="international_vat_number"
                    >
                        <span>{{ $t('partners::partners.columns.international_vat_number').ucFirst() }}</span>
                        <span v-if="isRequiredField('international_vat_number')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('international_vat_number')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="international_vat_number"
                            v-model="model.international_vat_number"
                            :class="{'border-danger': states.international_vat_number === false || isRequiredField('international_vat_number')}"
                            :placeholder="$t('partners::partners.placeholders.international_vat_number').ucFirst()"
                            class="form-control"
                            type="text"
                            :readonly="merge"
                        />
                    </div>
                    <div :class="{'d-block': states.international_vat_number === false}" class="invalid-feedback">
                        {{ errors.international_vat_number }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.company_size_id === false}"
                        class="form-label"
                        for="company_size_id"
                    >
                        <span>{{ $t('partners::partners.columns.company_size_id').ucFirst() }}</span>
                        <span v-if="isRequiredField('company_size_id')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('company_size_id')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <select
                            id="company_size_id"
                            v-model="model.company_size_id"
                            :class="{'border-danger': states.company_size_id === false || isRequiredField('company_size_id')}"
                            class="form-select"
                            name="company_size_id"
                            :disabled="merge"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('partners::partners.placeholders.company_size_id').ucFirst() }}
                            </option>
                            <option v-for="(companySize, key) in companySizes" :key="key" :value="companySize.id">{{
                                    companySize.translation_key ? $t(companySize.translation_key).ucFirst() : companySize.name
                                }}
                            </option>
                        </select>
                    </div>
                    <div :class="{'d-block': states.company_size_id === false}" class="invalid-feedback">
                        {{ errors.company_size_id }}
                    </div>
                </div>
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.industry_id === false}"
                        class="form-label"
                        for="industry_id"
                    >
                        <span>{{ $t('partners::partners.columns.industry_id').ucFirst() }}</span>
                        <span v-if="isRequiredField('industry_id')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('industry_id')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <select
                            id="industry_id"
                            v-model="model.industry_id"
                            :class="{'border-danger': states.industry_id === false || isRequiredField('industry_id')}"
                            class="form-select"
                            name="industry_id"
                            :disabled="merge"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('partners::partners.placeholders.industry_id').ucFirst() }}
                            </option>
                            <option v-for="(industry, key) in industries" :key="key" :value="industry.id">
                                {{ industry.translation_key ? $t(industry.translation_key).ucFirst() : industry.name }}
                            </option>
                        </select>
                    </div>
                    <div :class="{'d-block': states.industry_id === false}" class="invalid-feedback">
                        {{ errors.industry_id }}
                    </div>
                </div>
                <div :class="{'col-lg-4': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.lead_source_id === false}"
                        class="form-label"
                        for="lead_source_id"
                    >
                        <span>{{ $t('partners::partners.columns.lead_source_id').ucFirst() }}</span>
                        <span v-if="isRequiredField('lead_source_id')" class="ms-2 text-warning">*</span>
                    </label>
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('lead_source_id')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <select
                            id="lead_source_id"
                            v-model="model.lead_source_id"
                            :class="{'border-danger': states.lead_source_id === false || isRequiredField('lead_source_id')}"
                            class="form-select"
                            name="lead_source_id"
                            :disabled="merge"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('partners::partners.placeholders.lead_source_id').ucFirst() }}
                            </option>
                            <option v-for="(leadSource, key) in leadSources" :key="key" :value="leadSource.id">{{
                                    leadSource.translation_key ? $t(leadSource.translation_key).ucFirst() : leadSource.name
                                }}
                            </option>
                        </select>
                    </div>
                    <div :class="{'d-block': states.lead_source_id === false}" class="invalid-feedback">
                        {{ errors.lead_source_id }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div :class="{'col-lg-6': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.email === false}"
                        class="-form-label"
                        for="email"
                    >
                        <span>{{ $t('partners::partners.columns.email').ucFirst() }}</span>
                        <span v-if="isRequiredField('email')" class="ms-2 text-warning">*</span>
                    </label
                    >
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('email')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <input
                            id="email"
                            v-model="model.email"
                            :class="{'border-danger': states.email === false || isRequiredField('email')}"
                            :placeholder="$t('partners::partners.placeholders.email').ucFirst()"
                            class="form-control"
                            type="email"
                            :readonly="merge"
                        />
                    </div>
                    <div :class="{'d-block': states.email === false}" class="invalid-feedback">{{ errors.email }}</div>
                </div>
                <div :class="{'col-lg-6': !oneLine, 'col-12': oneLine}">
                    <label
                        :class="{'text-danger': states.phone === false}"
                        class="form-label"
                        for="phone"
                    >
                        <span>{{ $t('partners::partners.columns.phone').ucFirst() }}</span>
                        <span v-if="isRequiredField('phone')" class="ms-2 text-warning">*</span>
                    </label
                    >
                    <div class="d-flex mb-4" :class="{'flex-row-reverse': directionRight}">
                        <b-button v-if="merge" @click="setMergeProp('phone')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                            <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                        </b-button>
                        <vue-tel-input
                            class="w-100"
                            id="phone"
                            v-model="phone"
                            :inputOptions="{
                                id: 'phone',
                                placeholder: $t('partners::partners.placeholders.phone').ucFirst(),
                                styleClasses: {'border-danger': states.phone === false || isRequiredField('phone')},
                            }"
                            :invalidMsg="errors.phone"
                            defaultCountry="hu"
                            validCharactersOnly
                            mode="international"
                            :disabled="merge"
                        ></vue-tel-input>
                    </div>
                    <div :class="{'d-block': states.phone === false}" class="invalid-feedback">{{ errors.phone }}</div>
                </div>
            </div>
            <div class="row mb-4" :key="updateUser">
                <label
                    :class="{'text-danger': states.users === false}"
                    class="form-label"
                    for="users"
                >
                    <span>{{ $t('partners::partners.relationships.users').ucFirst() }}</span>
                    <span v-if="isRequiredField('users')" class="ms-2 text-warning">*</span>
                </label
                >
                <div v-if="merge" class="d-flex w-100 mb-4" :class="{'flex-row-reverse': directionRight}">
                    <b-button @click="setMergeProp('users')" variant="outline-warning" :class="{'me-2': !directionRight, 'ms-2': directionRight}">
                        <i class="fas" :class="{'fa-arrow-left': !directionRight, 'fa-arrow-right': directionRight}"></i>
                    </b-button>
                    <div v-if="merge">
                        <span v-for="(id) in partnerUsers" :key="id"
                              :class="getRandomColor()"
                              class="py-2 font-size-16 mb-2 badge rounded-pill d-inline-flex align-items-center me-2">
                            <i v-if="main_user === id" class="fas fa-user-tie me-2"></i>
                            {{ getUserName(id) }}
                        </span>
                    </div>
                </div>
                <div class="col-lg-12" v-else>
                    <select
                        id="users"
                        class="form-select"
                        v-model="user"
                        :class="{'border-danger': states.users === false || isRequiredField('users')}"
                    >
                        <option disabled :value="user === undefined ? undefined : null">{{ $t('partners::partners.placeholders.users').ucFirst() }}</option>
                        <option v-for="(user, key) in filteredUsers" :key="key" :value="user.id">{{ user.name }}</option>
                    </select>
                    <input
                        v-if="false"
                        id="users"
                        v-model="user"
                        :class="{'border-danger': states.users === false}"
                        :placeholder="$t('partners::partners.placeholders.users').ucFirst()"
                        class="form-control"
                        list="user-list"
                        type="text"
                    />
                    <div :class="{'d-block': states.users === false}" class="invalid-feedback">{{ errors.users }}</div>
                    <datalist v-if="false" id="user-list">
                        <option v-for="(user, key) in users" :key="key" :value="user.id">{{ user.name }}</option>
                    </datalist>
                    <div class="mt-1">
                        <span v-for="(id) in partnerUsers" :key="id"
                              :class="getRandomColor()"
                              class="pt-1 badge rounded-pill d-inline-flex align-items-center me-2">
                        <span class="d-flex align-items-center">
                            <input :checked="Number(main_user) === id" :value="id" class="me-1" name="is_main"
                                   type="checkbox" @change="setMainUser"/>
                            {{ getUserName(id) }}
                        </span>
                        <i class="ms-2 bx bx-x cursor-pointer" @click="remove(id)"></i>
                    </span>
                    </div>
                </div>
            </div>
            <template v-if="!withoutExtraFields">
                <template v-for="(field, index) in fields" :key="index">
                    <additional-versionable-field
                        v-if="field.versionable"
                        v-model="model[field.transformed_name]"
                        :is-required="isRequiredField(field.transformed_name)"
                        :field="field"
                        :state="states[field.transformed_name]"
                        :error="errors[field.transformed_name]"
                        @update:modelValue="newValue => model[field.transformed_name] = newValue"
                    ></additional-versionable-field>

                    <div v-else class="row mb-4">
                        <additional-field
                            v-model="model[field.transformed_name]"
                            :is-required="isRequiredField(field.transformed_name)"
                            :field="field"
                            :state="states[field.transformed_name]"
                            :error="errors[field.transformed_name]"
                            @update:modelValue="newValue => model[field.transformed_name] = newValue"
                        ></additional-field>
                    </div>
                </template>
            </template>
            <div class="row justify-content-end">
                <div class="col-12 text-center">
                    <button v-if="!merge && !withoutSaveBtn" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import AdditionalField from "../elements/additional-field.vue";
import AdditionalVersionableField from "../elements/additional-versionable-field.vue";

export default {
    name: "PartnerForm",
    components: {AdditionalVersionableField, AdditionalField},

    emits: ['saved', 'merge'],

    props: {
        partner: {
            type: Object,
            required: true
        },

        useAuthenticatedAsDefault: {
            type: Boolean,
            default: false
        },

        withoutExtraFields: {
            type: Boolean,
            default: false
        },

        oneLine: {
            type: Boolean,
            default: false
        },

        merge: {
            type: Boolean,
            default: false
        },

        directionRight: {
            type: Boolean,
            default: true
        },

        withoutSaveBtn: {
            type: Boolean,
            default: false
        },

        requiredFields: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'partner/loading',
            'states': 'partner/states',
            'errors': 'partner/errors',
            'authenticated': 'user/authenticated',
            'users': 'user/all',
            fields: 'partner/fields',
            companySizes: 'companySize/all',
            industries: 'industry/all',
            leadSources: 'leadSource/all',
            fieldVersions: 'fieldVersion/all',
            configuration: 'app/configuration'
        }),

        vatNumberIsRequired: function () {
            return !!this.configuration?.partners?.vat_number_required;
        },

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        partnerUsers: function () {
            const arr = this.model.users

            if (!Array.isArray(arr)) {
                return arr
            }

            return arr.map(item => {
                if (typeof item === 'object') {
                    if (item.pivot.is_main) {
                        this.main_user = item.id
                    }
                    return item.id;
                }
                return item
            });
        },

        filteredUsers: function () {
            if (!this.users || !this.model) {
                return []
            }

            if (!this.model.users) {
                return this.users
            }

            const ids = this.model.users.reduce((arr, user) => {
                if (typeof user === 'object') {
                    arr.push(user.id)
                } else {
                    arr.push(user)
                }

                return arr;
            }, []);

            return this.users.filter(user => !ids.includes(user.id));
        },
    },

    data: function () {
        return {
            model: {},
            main_user: null,
            user: null,
            phone: (this.partner && this.partner.phone) || '',
            dynamicSkeleton: {},
            updateUser: 1
        }
    },

    methods: {
        setMergeProp: function (prop) {
            const data = {}

            data[prop] = this.model[prop]

            if (prop === 'users') {
                data.main_user = this.main_user
            }

            this.$emit('merge', data);
        },

        setMainUser: function (event) {
            this.main_user = event.target.value;
        },

        getUserName: function (id) {
            const user = this.users.find(user => user.id === id)

            return user ? user.name : id
        },

        getRandomColor: function () {
            const classes = ['bg-primary', 'bg-info', 'bg-success', 'bg-warning', 'bg-danger'];

            return classes[Math.floor(Math.random() * classes.length)]
        },

        remove: function (id) {
            const index = this.model.users.findIndex(user => {
                return typeof user === 'object'
                    ? user.id === id
                    : user === id;
            })

            this.model.users.splice(index, 1)
        },

        setDefaultUser: function () {
            if (!this.useAuthenticatedAsDefault) return;

            if (!Array.isArray(this.model.users)) {
                this.model.users = []
            }

            if (
                ! this.model.users.includes(this.authenticated.id)
                && !this.model.users.filter(user => {
                    if (typeof user === 'object') {
                        return user.id === this.authenticated.id
                    }
                    return false;
                }).length
            ) {
                this.model.users.push(this.authenticated.id);
                this.main_user = this.authenticated.id;
            }
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({with: ['fields', 'fields.fieldType', 'fieldValues', 'fieldValues.fieldVersion', 'fieldValues.files']}, this.model, {main_user: this.main_user, users: this.partnerUsers})

            return this.$store.dispatch(`partner/${method}`, data).then(saved => {
                if (!['string', 'number'].includes(typeof saved.phone)) {
                    saved.phone = '';
                }
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.'+'/partners/:id(\\d+)/base-information/:type?', {id: this.model.id, type: this.$route.params.type}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, this.dynamicSkeleton, JSON.parse(JSON.stringify(model)))
        },

        isRequiredField: function (field) {
            return this.requiredFields?.includes(field);
        },
    },

    watch: {
        partner: {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.setModel(value)
                this.phone = (this.partner && this.partner.phone) || ''
            }
        },

        user: function (id) {
            if (!id) {
                return
            }

            let n = Number(id)

            if (!this.model.users) {
                this.model.users = [];
            }

            if (!this.model.users.includes(n)) {
                this.model.users.push(n);
                if (!this.main_user) {
                    this.main_user = id;
                }
                this.updateUser++;
            }

            this.user = null
        },

        phone: function (value) {
            this.model.phone = value
            //.replace(/\s|\t|\n|\+/g, '')
        },

        fields: function (fields) {
            const skeleton = {}

            fields.forEach(field => {
                if (field.versionable) {
                    skeleton[field.transformed_name] = [
                        /*{
                            version: {
                                name: null,
                                date: null
                            },
                            item: {}
                        }*/
                    ];
                }
            })

            this.dynamicSkeleton = skeleton

            this.setModel(this.model)
        },
    },

    created() {
        this.setModel(this.partner)
        this.setDefaultUser()
        this.$store.dispatch('partner/fields', {owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType'})
    }
}
</script>

<style scoped>

</style>
